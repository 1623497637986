<!-- Banner Area -->
<div class="banner-area-three banner-bg">
    <div class="container">
        <div class="banner-content banner-content-three">
            <span>Since 2020</span>
            <h1>Innovation Drives Success in the Digital Age</h1>
            <p>Empowering businesses with cutting-edge software solutions. Trust our expertise to elevate your digital
                journey.</p>

            <div class="banner-btn">
                <a routerLink="/contact" class="contact-btn">Contact Us</a>
                <a routerLink="/contact" class="get-btn">Get A Quote</a>
            </div>
        </div>
    </div>
</div>
<!-- Banner Area End -->

<!-- Status Area -->
<div class="status-area">
    <div class="container status-bg">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="status-card">
                    <h3>Software Development</h3>
                    <p>We offer software development, web development, and mobile app development to create robust and
                        scalable solutions.</p>
                    <!-- <i class='flaticon-magnifying-glass'></i> -->
                    <div class="status-bottom-1"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="status-card">
                    <h3>Design and UX</h3>
                    <p>Our UI/UX services ensure user-friendly and engaging designs that enhance user experience and
                        drive business success.</p>
                    <!-- <i class='flaticon-bank'></i> -->
                    <div class="status-bottom-2"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="status-card">
                    <h3>Advanced Solutions</h3>
                    <p>Leverage our expertise in data science, AI, and our GEN AI Chatbot for innovative solutions that
                        drive efficiency
                        and business growth.</p>

                    <!-- <i class='flaticon-settings'></i> -->
                    <div class="status-bottom-3"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Status Area End -->

<!-- Services Area -->
<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="section-title">
                    <span>Our Services</span>
                    <!-- <h2>Our Best Consulting Services Make You To Reach Your Goal</h2> -->
                    <h2>Expert Consulting for Digital Transformation</h2>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="service-text">
                    <p>Experience unparalleled expertise with our comprehensive suite of IT services. From innovative
                        software development and seamless web solutions to cutting-edge mobile apps and advanced data
                        science, we tailor our solutions to meet your unique business needs. Trust us to enhance your
                        digital presence and guide your business towards technological excellence and sustainable
                        growth.</p>

                </div>
            </div>
        </div>

        <div class="row pb-20">
            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a class="services-icon"><i class="flaticon-settings"></i></a>
                    <h3><a>Software Development</a></h3>
                    <p>Create custom software solutions tailored to your business needs for improved functionality and
                        growth.</p>

                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a class="services-icon"><i class="flaticon-menu"></i></a>
                    <h3><a>Web Development</a></h3>
                    <p>Build and optimize websites with modern technologies to enhance user experience and drive
                        engagement.</p>

                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a class="services-icon"><i class="flaticon-play-button"></i></a>
                    <h3><a>Mobile App Development</a></h3>
                    <p>Develop high-performance mobile apps for iOS and Android to enhance accessibility and user
                        engagement.</p>
                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a class="services-icon"><i class="flaticon-quote"></i></a>
                    <h3><a>UI/UX Design</a></h3>
                    <p>Create intuitive and engaging user interfaces that improve usability and drive user satisfaction.
                    </p>
                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a class="services-icon"><i class="flaticon-analytics"></i></a>
                    <h3><a>Data Science</a></h3>
                    <p>Leverage data analytics and machine learning to gain insights and drive strategic
                        decision-making.</p>
                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a class="services-icon"><i class="flaticon-double-right-arrows-angles"></i></a>
                    <h3><a>AI Solutions</a></h3>
                    <p>Implement AI-driven solutions like chatbots and automation to enhance operational efficiency.</p>
                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a class="services-icon"><i class="flaticon-magnifying-glass"></i></a>
                    <h3><a>Cloud Solutions</a></h3>
                    <p>Develop and manage cloud-based solutions to enhance scalability, security, and accessibility for
                        your business.</p>
                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a class="services-icon"><i class="flaticon-settings"></i></a>
                    <h3><a>Cybersecurity</a></h3>
                    <p>Protect your digital assets with advanced cybersecurity measures to safeguard against threats and
                        breaches.</p>
                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card services-card-bg">
                    <a class="services-icon"><i class="flaticon-sort"></i></a>
                    <h3><a>Systems Integration</a></h3>
                    <p>Integrate diverse IT systems and applications to streamline operations and improve overall
                        efficiency.</p>
                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>
        </div>


        <div class="service-view-btn text-center">
            <a routerLink="/services" class="view-btn">View More</a>
        </div>
    </div>

    <!-- <div class="service-shape">
        <div class="shape1"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape8.png" alt="Images"></div>
    </div> -->
</div>
<!-- Services Area End -->

<!-- Video Area -->
<!-- <div class="video-area video-bg2">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="video-btn">
                    <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=11s" class="video-play-btn popup-btn"><i
                            class="flaticon-play-button"></i></a>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="video-content">
                    <div class="section-title">
                        <span>Intro Video</span>
                        <h2>Explore Us Watch This Full Video and Know Us</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid mr-0 pr-0">
        <div class="guidelines-area guidelines-max guidelines-bg2">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="guidelines-content">
                        <h2>A Proper Guideline Can Make Your Business Successful</h2>
                        <p>Proin magna felis, tincidunt eget lectus id, maximus ultricies eros. Aliquam eu lacus vitae
                            nulla faucibus mollis. Proin velit mi, molestie commodo neque laoreet a.Fusce cursus iaculis
                            elit vitae auctor. Mauris venenatis vestibulum eros, sed turpis molestie ac. Nunc convallis
                            nisi in maximus tincidunt.</p>
                        <div class="signature"><img src="assets/img/signature.png" alt="Images"></div>
                        <div class="content">
                            <h3>Smithy Karnes</h3>
                            <span>Founder & CEO</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Video Area End -->

<!-- About Area -->
<div class="about-area pt-100 pb-70" id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 pl-0">
                <div class="about-img-3">
                    <img src="assets/img/about/2.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content about-content-max">
                    <span>About Us</span>
                    <h2>Your Strategic IT Consultancy Partner
                    </h2>
                    <p>Founded in 2018, Cipher Solutions has established itself as a leading IT consultancy specializing
                        in delivering innovative software, APIs, websites, and mobile apps. Our team leverages extensive
                        experience in the financial and retail sectors to provide tailored solutions that drive business
                        growth and technological advancement.</p>
                    <p>We pride ourselves on our commitment to excellence and our ability to transform complex
                        challenges into effective, user-friendly solutions. Our client-focused approach ensures that we
                        address unique needs with precision and creativity, enabling businesses to thrive in a
                        competitive digital landscape.</p>
                    <div class="about-btn">
                        <a routerLink="/about" class="learn-btn">Learn More</a>
                        <a routerLink="/contact" class="get-btn">Get A Quote</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Area End -->

<!-- Guidelines Area -->
<div class="guidelines-area-two">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12 pl-0">
                <div class="guidelines-img">
                    <img src="assets/img/homepagequote.png" alt="Images">
                </div>
            </div>

            <div class="col-lg-7 col-md-12 pr-0">
                <div class="guidelines-content-bg">
                    <div class="guidelines-content-two">
                        <h2>Empowering Businesses Through Strategic IT Solutions</h2>
                        <p>At Cipher Solutions, we believe in providing tailored technology solutions that drive
                            innovation and success.
                            Our commitment is to empower businesses with cutting-edge software, insightful data
                            analytics, and strategic
                            IT advice, ensuring seamless operations and optimizing digital performance.</p>
                        <!-- <div class="signature"><img src="assets/img/signature2.png" alt="Images"></div> -->
                        <div class="content">
                            <h3>Marium Majeed</h3>
                            <span>Co Founder & CEO</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Guidelines Area End -->

<!-- Choose Area -->
<div class="choose-area-two pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12 pl-0">
                <div class="choose-content choose-content-bg">
                    <div class="choose-max">
                        <div class="section-title">
                            <span>Why Choose Us</span>
                            <h2>Your Trusted Partner for Comprehensive IT Solutions</h2>
                            <p>At Cipher Solutions, we are dedicated to providing personalized and strategic technology
                                solutions
                                tailored to your business needs. Our expertise spans across software development, data
                                analytics,
                                IT consulting, and system integration. Trust us to guide your business towards
                                innovation and
                                success with our commitment to excellence and integrity.</p>
                            <a routerLink="/contact" class="default-btn">Get A Quote</a>
                        </div>

                        <div class="row border-top">
                            <div class="col-lg-6 col-sm-6">
                                <div class="choose-content-list choose-content-list-color">
                                    <i class="flaticon-sort"></i>
                                    <div class="content">
                                        <h3>Fast Service</h3>
                                        <p>Quick and efficient service to meet your IT needs promptly and accurately.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="choose-content-list choose-content-list-color">
                                    <i class="flaticon-briefcase"></i>
                                    <div class="content">
                                        <h3>Easy to Contact</h3>
                                        <p>Always available to address your queries and provide timely support and
                                            advice.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="choose-content-list choose-content-list-color">
                                    <i class="flaticon-analytics"></i>
                                    <div class="content">
                                        <h3>IT Strategy</h3>
                                        <p>Strategic IT planning to guide your company towards innovation and success.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="choose-content-list choose-content-list-color">
                                    <i class="flaticon-chess-pieces"></i>
                                    <div class="content">
                                        <h3>Experienced Team</h3>
                                        <p>Highly skilled professionals with extensive experience to ensure top-notch IT
                                            solutions and consulting.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12 pr-0">
                <div class="choose-img-3">
                    <img src="assets/img/choose/1240x1050.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->

<!-- Client Area -->
<div class="client-area-two ptb-100">
    <div class="container">
        <div class="section-title text-center">
            <span>Clients Review</span>
            <h2>Our Valued Clients Share Their Success Stories</h2>
            <p>At Cipher Solutions, we take pride in our clients' achievements. Our personalized and
                strategic IT solutions have empowered businesses to reach new heights. Hear directly from those
                who have experienced our commitment to excellence and integrity.</p>
        </div>

        <div class="client-slider owl-carousel owl-theme pt-45">
            <div class="client-card">
                <h3>Dilshad Patel</h3>
                <span>CEO Of Pink Hijabi</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>During our startup Cipher Solutions created a perfect brand guide, stunning logo, cohesive color
                    palettes, and seamless
                    online store setup.
                </p>
                <i class="flaticon-quote client-card-quote"></i>
                <!-- <div class="client-card-img">
                    <img src="assets/img/client/client-img1.png" alt="Images">
                </div> -->
            </div>

            <div class="client-card">
                <h3>Arsalan Ahmed</h3>
                <span>CEO Of My Guy Online Shopping</span>
                <ul>
                    <li>
                        <i class='bx bxs-star' style="color: #001F3F;"></i>
                        <i class='bx bxs-star' style="color: #001F3F;"></i>
                        <i class='bx bxs-star' style="color: #001F3F;"></i>
                        <i class='bx bxs-star' style="color: #001F3F;"></i>
                        <i class='bx bxs-star' style="color: #001F3F;"></i>
                    </li>
                </ul>
                <p>Cipher Solutions designed and built our fully cloud based cost effective serverless website, iOS, and
                    android app with SEO support.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <!-- <div class="client-card-img">
                    <img src="assets/img/client/client-img4.png" alt="Images">
                </div> -->
            </div>

            <div class="client-card">
                <h3>Marium</h3>
                <span>CEO Of My Professional Accountants</span>
                <ul>
                    <li>
                        <i class='bx bxs-star' style="color: #001F3F;"></i>
                        <i class='bx bxs-star' style="color: #001F3F;"></i>
                        <i class='bx bxs-star' style="color: #001F3F;"></i>
                        <i class='bx bxs-star' style="color: #001F3F;"></i>
                        <i class='bx bxs-star' style="color: #001F3F;"></i>
                    </li>
                </ul>
                <p>In less than a week our website was up and running. At Cipher Solutions they spend good time
                    understanding the clients requirements.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <!-- <div class="client-card-img">
                    <img src="assets/img/client/client-img3.png" alt="Images">
                </div> -->
            </div>

            <div class="client-card">
                <h3>Johnson Dubula</h3>
                <span>Online Store Owner</span>
                <ul>
                    <li>
                        <i class='bx bxs-star' style="color: #001F3F;"></i>
                        <i class='bx bxs-star' style="color: #001F3F;"></i>
                        <i class='bx bxs-star' style="color: #001F3F;"></i>
                        <i class='bx bxs-star' style="color: #001F3F;"></i>
                        <i class='bx bxs-star' style="color: #001F3F;"></i>
                    </li>
                </ul>
                <p>Cipher Solutions create all our Graphic Designs, they understand our requirements very well and
                    provide quality work.</p>
                <i class="flaticon-quote client-card-quote"></i>
            </div>
        </div>
    </div>
</div>
<!-- Client Area End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Expand Your Reach and Lead Your Industry</h2>
                <p>Unlock your business potential with expert IT services. Let us guide you to success.</p>
            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Request a Consultation</h2>
                            <p>Contact us today for a personalized consultation. Discover how we can elevate your
                                business.</p>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required
                                            placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required
                                            placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required
                                            class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                                            required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8"
                                            required placeholder="Your Message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->