import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // this.setTitleAndMetaTags();
  }

  // setTitleAndMetaTags(): void {
  //   const pageTitle = 'About | Cipher Solutions';
  //   const pageDescription = 'Founded in 2018, Cipher Solutions has established itself as a leading IT consultancy specializing in delivering innovative software, APIs, websites, and mobile apps.';
  //   const pageImage = 'URL to an image that represents your page';
  //   const pageUrl = 'www.ciphersolutions.co.za/about';

  //   // Set the title
  //   this.titleService.setTitle(pageTitle);

  //   // Set meta tags
  //   this.metaService.updateTag({ name: 'description', content: pageDescription });
  //   this.metaService.updateTag({ property: 'og:title', content: pageTitle });
  //   this.metaService.updateTag({ property: 'og:description', content: pageDescription });
  //   this.metaService.updateTag({ property: 'og:image', content: pageImage });
  //   this.metaService.updateTag({ property: 'og:url', content: pageUrl });
  //   this.metaService.updateTag({ property: 'og:type', content: 'website' });
  //   this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
  //   this.metaService.updateTag({ name: 'twitter:title', content: pageTitle });
  //   this.metaService.updateTag({ name: 'twitter:description', content: pageDescription });
  //   this.metaService.updateTag({ name: 'twitter:image', content: pageImage });
  // }
}
