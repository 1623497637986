<!-- Top Header -->
<header class="top-header">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="header-left">
                    <div class="header-left-card">
                        <ul>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bxl-whatsapp'></i>
                                </div>
                                <a href="https://wa.me/27743184606" target="_blank">+27 74 318 4606</a>
                            </li>
                            <li>
                                <div class="head-icon">
                                    <i class='bx bxs-envelope'></i>
                                </div>
                                <a href="mailto:info&#64;ciphersolutions.co.za">info&#64;ciphersolutions.co.za</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="header-right">
                    <div class="top-social-link">
                        <ul>
                            <li><a href="https://wa.me/27743184606" target="_blank"><i class='bx bxl-whatsapp'></i></a>
                            </li>
                            <li><a href="mailto:info@ciphersolutions.co.za" target="_blank"><i
                                        class='bx bxs-envelope'></i></a></li>
                            <!-- <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> -->
                        </ul>
                    </div>

                    <div class="language">
                        <ul>
                            <li>
                                <a href="javascript:void(0)"><i class='bx bx-world language-icon'></i> ENG <i
                                        class='bx bx-chevron-down'></i></a>
                                <ul>
                                    <li><a href="#">ENG</a></li>
                                    <!-- <li><a href="#">DE</a></li>
                                    <li><a href="#">UAE</a></li> -->
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Top Header End -->

<!-- Start Navbar Area -->
<div class="navbar-area">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img src="assets/img/logo1.png" alt="Logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav m-auto">
                    <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Home</a>
                        <!-- <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Home One</a></li>
                            <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Home Two</a></li>
                            <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Home Three</a></li>
                        </ul> -->
                    </li>
                    <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">About Us</a></li>

                    <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages <i
                                class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Team</a></li>
                            <li class="nav-item"><a routerLink="/apply-now" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Apply Now</a></li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link dropdown-toggle">Projects
                                    <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/projects" class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Projects</a></li>
                                    <li class="nav-item"><a routerLink="/projects-details" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Projects
                                            Details</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/case-study" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Case Study</a></li>
                            <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                            <li class="nav-item"><a routerLink="/testimonials" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Testimonials</a>
                            </li>
                            <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">404 Error</a></li>
                            <li class="nav-item"><a routerLink="/sign-in" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Sign In</a></li>
                            <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>
                            <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Terms &
                                    Conditions</a></li>
                            <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Privacy
                                    Policy</a></li>
                            <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                        </ul>
                    </li> -->

                    <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Services</a>
                        <!-- <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Services Style One</a></li>
                            <li class="nav-item"><a routerLink="/services-2" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Services Style Two</a></li>
                            <li class="nav-item"><a routerLink="/services-details" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Services
                                    Details</a></li>
                        </ul> -->
                    </li>
                    <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog <i
                                class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/blog-1" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Blog Style One</a></li>
                            <li class="nav-item"><a routerLink="/blog-2" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Blog Style Two</a></li>
                            <li class="nav-item"><a routerLink="/blog-details" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blog Details</a>
                            </li>
                        </ul>
                    </li> -->

                    <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Home </a>
                        <!-- <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Home One</a></li>
                            <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Home Two</a></li>
                            <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Home Three</a>
                            </li>
                        </ul> -->
                    </li>
                    <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">About Us</a></li>
                    <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages <i
                                class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Team</a></li>
                            <li class="nav-item"><a routerLink="/apply-now" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Apply Now</a></li>
                            <li class="nav-item"><a href="javascript:void(0)" class="nav-link dropdown-toggle">Projects
                                    <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/projects" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                            (click)="toggleClass()">Projects</a></li>
                                    <li class="nav-item"><a routerLink="/projects-details" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                            (click)="toggleClass()">Projects Details</a></li>
                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/case-study" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Case Study</a>
                            </li>
                            <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">FAQ</a></li>
                            <li class="nav-item"><a routerLink="/testimonials" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                    (click)="toggleClass()">Testimonials</a></li>
                            <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">404 Error</a></li>
                            <li class="nav-item"><a routerLink="/sign-in" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Sign In</a></li>
                            <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Sign Up</a></li>
                            <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                    (click)="toggleClass()">Terms & Conditions</a></li>
                            <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                    (click)="toggleClass()">Privacy Policy</a></li>
                            <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Coming Soon</a>
                            </li>
                        </ul>
                    </li> -->
                    <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Services</a>
                        <!-- <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Services Style
                                    One</a></li>
                            <li class="nav-item"><a routerLink="/services-2" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Services Style
                                    Two</a></li>
                            <li class="nav-item"><a routerLink="/services-details" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                    (click)="toggleClass()">Services Details</a></li>
                        </ul> -->
                    </li>
                    <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link dropdown-toggle">Blogs <i
                                class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/blog-1" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Blog Style One</a>
                            </li>
                            <li class="nav-item"><a routerLink="/blog-2" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Blog Style Two</a>
                            </li>
                            <li class="nav-item"><a routerLink="/blog-details" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                    (click)="toggleClass()">Blog Details</a></li>
                        </ul>
                    </li> -->

                    <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}" (click)="toggleClass()">Contact</a></li>
                </ul>
                <div class="others-options d-flex align-items-center">
                    <!-- <div class="option-item">
                        <i class='search-btn bx bx-search'></i>
                        <i class='close-btn bx bx-x'></i>
                        <div class="search-overlay search-popup">
                            <div class='search-box'>
                                <form class="search-form">
                                    <input class="search-input" name="search" placeholder="Search" type="text">
                                    <button class="search-button" type="submit"><i class="bx bx-search"></i></button>
                                </form>
                            </div>
                        </div>
                    </div> -->
                    <div class="option-item">
                        <div class="menu-icon d-in-line">
                            <span class="burger-menu menu-icon-two"><i class='flaticon-menu'></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Sidebar Modal -->
<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-header">
            <div class="sidebar-logo">
                <img src="assets/img/logo1.png" alt="Image">
            </div>
            <span class="close-btn sidebar-modal-close-btn"><i class="bx bx-x"></i></span>
        </div>

        <div class="sidebar-about">
            <div class="title">
                <p>Contact us today for a personalized consultation. Discover how we can elevate your
                    business.</p>
            </div>
        </div>

        <div class="contact-us">
            <h2>Contact Us</h2>

            <ul>
                <!-- <li>
                    <i class='bx bx-location-plus'></i>32 Ivor St, Gillview, JHB, 2091
                </li> -->
                <li>
                    <i class='bx bx-mail-send'></i>
                    <a href="mailto:info&#64;ciphersolutions.co.za">info&#64;ciphersolutions.co.za</a>
                    <!-- <a href="#">Skype: example</a> -->
                </li>
                <li>
                    <i class='bx bx-phone-call'></i>
                    <a href="tel:+27743184606"> +27 74 318 4606</a>
                    <!-- <a href="tel:+612-831-345-70"> +612-831-345-70</a> -->
                </li>
                <li>
                    <i class='bx bxl-whatsapp'></i>
                    <a href="https://wa.me/27743184606"> Open WhatsApp</a>
                    <!-- <a href="tel:+612-831-345-70"> +612-831-345-70</a> -->
                </li>
            </ul>
        </div>

        <!-- <div class="sidebar-instagram-feed">
            <h2>Instagram</h2>
            <ul>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram1.jpg" alt="image"></a>
                </li>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram2.jpg" alt="image"></a>
                </li>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram3.jpg" alt="image"></a>
                </li>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram4.jpg" alt="image"></a>
                </li>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram5.jpg" alt="image"></a>
                </li>
                <li>
                    <a target="_blank" href="#"><img src="assets/img/instagram/instagram6.jpg" alt="image"></a>
                </li>
            </ul>
        </div>

        <div class="sidebar-follow-us">
            <h2>Follow Us</h2>

            <ul class="social-wrap">
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
            </ul>
        </div> -->
    </div>
</div>
<!-- End Sidebar Modal -->