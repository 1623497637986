<!-- Inner Banner -->
<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact Us</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Contact Section -->
<div class="contact-section pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-4">
                <div class="contact-card">
                    <i class='bx bxl-whatsapp'></i>
                    <div class="content">
                        <h3>Phone</h3>
                        <p><a href="https://wa.me/27743184606">+27 74 318 4606</a></p>
                        <!-- <span><a href="tel:+61283175421">+612-831-754-21</a></span> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-4">
                <div class="contact-card">
                    <i class='bx bx-map'></i>
                    <div class="content">
                        <h3>Address</h3>
                        <p>32 Ivor St, Gillview, JHB</p>
                        <!-- <span>Gauteng</span> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-4 offset-lg-0 offset-md-0 offset-sm-3">
                <div class="contact-card">
                    <i class='bx bx-envelope'></i>
                    <div class="content">
                        <h3>Email</h3>
                        <p><a href="mailto:info&#64;ciphersolutions.co.za">info&#64;ciphersolutions.co.za</a></p>
                        <!-- <span><a href="hello&#64;gloz.com">hello&#64;gloz.com</a></span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Section End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Expand Your Reach and Lead Your Industry</h2>
                <p>Unlock your business potential with expert IT services. Let us guide you to success.</p>

            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Contact Us</h2>
                            <p>Contact us today for a personalized consultation. Discover how we can elevate your
                                business.</p>
                        </div>

                        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input formControlName="name" type="text" name="name" id="name"
                                            class="form-control" required placeholder="Name">
                                        <span style="color: #fe4848;" *ngIf="nameError">Name required.</span>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input formControlName="email" type="email" name="email" id="email"
                                            class="form-control" required placeholder="Email">
                                        <span style="color: #fe4848;" *ngIf="emailError">Valid Email required.</span>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input formControlName="phone_number" type="text" name="phone_number"
                                            id="phone_number" required class="form-control" placeholder="Phone">
                                        <span style="color: #fe4848;" *ngIf="phoneError">Valid Contact required.</span>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input formControlName="msg_subject" type="text" name="msg_subject"
                                            id="msg_subject" class="form-control" required placeholder="Your Subject">
                                        <span style="color: #fe4848;" *ngIf="subError">Enter Subject.</span>

                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea formControlName="message" name="message" class="form-control"
                                            id="message" cols="30" rows="8" required
                                            placeholder="Your Message"></textarea>
                                        <span style="color: #fe4848;" *ngIf="msgError">Please enter your query.</span>

                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->

<!-- Map Area -->
<!-- <div class="map-area">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d424146.10263665376!2d150.65179679116446!3d-33.847356725232856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae665e892fdd%3A0x3133f8d75a1ac251!2sSydney%20Opera%20House!5e0!3m2!1sen!2sbd!4v1599988407815!5m2!1sen!2sbd"></iframe>
</div> -->
<!-- Map Area End -->