import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  isLoading = false;
  successMessage: string;
  nameError = false;
  emailError = false;
  phoneError = false;
  subError = false;
  msgError = false;

  constructor(private fb: FormBuilder, private http: HttpClient) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', Validators.required],
      msg_subject: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  onSubmit(): void {
    this.isLoading = true;
    this.nameError = false;
    this.emailError = false;

    if (this.contactForm.invalid) {
      if (!this.contactForm.controls['name'].valid) {
        this.nameError = true;
      }

      if (!this.contactForm.controls['email'].valid) {
        this.emailError = true;
      }

      if (!this.contactForm.controls['phone_number'].valid) {
        this.phoneError = true;
      }

      if (!this.contactForm.controls['msg_subject'].valid) {
        this.subError = true;
      }

      if (!this.contactForm.controls['message'].valid) {
        this.msgError = true;
      }
      return;
    }

    const formData = this.contactForm.value;

    this.http.post('https://us-central1-mg-customer-5c7de.cloudfunctions.net/sendEmail', formData)
      .subscribe({
        next: (response) => {
          this.contactForm.reset();
          this.isLoading = false;
          alert('Email sent successfully');
        },
        error: error => {
          this.contactForm.reset();
          alert('There was an error sending your email. Please try again.');
          console.error('There was an error!', error);
          this.isLoading = false;
        }
      });

  }
}
