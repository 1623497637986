<!-- Footer Area -->
<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo1.png" alt="Images"></a>
                    </div>
                    <p>Cipher Solutions offers expert IT consultancy services to help businesses grow and
                        achieve success.</p>
                    <ul class="social-link">
                        <!-- <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> -->
                        <!-- <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> -->
                        <!-- <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> -->
                        <li><a href="https://wa.me/27743184606" target="_blank"><i class='bx bxl-whatsapp'></i></a></li>
                        <li><a href="mailto:info&#64;ciphersolutions.co.za" target="_blank"><i
                                    class='bx bxs-envelope'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="footer-widget">
                    <h3>Useful Links</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/services">Services</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                        <!-- <li><a routerLink="/blogs">Blogs</a></li> -->
                        <!-- <li><a routerLink="/faq">FAQs</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <h3>Contact Us</h3>

                    <ul class="footer-list-two">
                        <!-- <li>
                            <i class='bx bx-location-plus'></i>
                            32 Ivor St, Gillview, Jhb
                        </li> -->
                        <li>
                            <i class='bx bx-phone'></i>
                            <a href="tel:+27743184606">+27 74 318 4606</a>
                        </li>
                        <li>
                            <i class='bx bxl-whatsapp'></i>
                            <a href="https://wa.me/27743184606">WhatsApp</a>
                        </li>
                        <li>
                            <i class='bx bxs-envelope'></i>
                            <a href="mailto:info&#64;ciphersolutions.co.za">info&#64;ciphersolutions.co.za</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-2">
                    <h3>Subscribe</h3>

                    <div class="newsletter-area">
                        <form class="newsletter-form">
                            <input type="email" class="form-control" placeholder="Your Email*" name="EMAIL">
                            <button class="default-btn" type="submit">Subscribe </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer Area End -->

<!-- Copy-Right Area -->
<div class="copy-right-area">
    <div class="container">
        <div class="copy-right-text text-center">
            <p>© 2024 copyright <a href="">Cipher Solutions.</a> All rights reserved</p>
        </div>
    </div>
</div>
<!-- Copy-Right Area End -->