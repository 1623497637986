// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAvrqhWsapPmCtoQi5AmL5ggPPgTk0fkS8",
    authDomain: "cipher-solutionz.firebaseapp.com",
    projectId: "cipher-solutionz",
    storageBucket: "cipher-solutionz.appspot.com",
    messagingSenderId: "384793415410",
    appId: "1:384793415410:web:969f7591f810c914ad4a6b",
    measurementId: "G-72HL21D7JE"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
