<!-- Inner Banner -->
<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title text-center">
            <h3>About Us</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- About Area -->
<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img about-img-before">
                    <img src="assets/img/about/4.png" alt="Images">

                    <div class="about-img-small">
                        <img src="assets/img/about/3.png" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h2>Your Strategic IT Consultancy Partner
                    </h2>

                    <p>Founded in 2018, Cipher Solutions has established itself as a leading IT consultancy specializing
                        in delivering innovative software, APIs, websites, and mobile apps. Our team leverages extensive
                        experience in the financial and retail sectors to provide tailored solutions that drive business
                        growth and technological advancement.</p>
                    <p>We pride ourselves on our commitment to excellence and our ability to transform complex
                        challenges into effective, user-friendly solutions. Our client-focused approach ensures that we
                        address unique needs with precision and creativity, enabling businesses to thrive in a
                        competitive digital landscape.</p>
                    <!-- <div class="row">
                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-medal"></i>
                                <div class="content">
                                    <h3>1200+</h3>
                                    <span>Award Winner</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-team"></i>
                                <div class="content">
                                    <h3>10+</h3>
                                    <span>Years Experience</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-briefcase"></i>
                                <div class="content">
                                    <h3>Trusted by</h3>
                                    <span>Leading South African Institutions</span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="about-btn">
                        <a routerLink="/contact" class="learn-btn">Get A Quote</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Area End -->

<!-- Choose Area -->
<div class="choose-area-two choose-bg pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="choose-content choose-content-title">
                    <div class="section-title">
                        <h2>Your Trusted Partner for Comprehensive IT Solutions</h2>
                        <p>At Cipher Solutions, we are dedicated to providing personalized and strategic technology
                            solutions
                            tailored to your business needs. Our expertise spans across software development, data
                            analytics,
                            IT consulting, and system integration. Trust us to guide your business towards
                            innovation and
                            success with our commitment to excellence and integrity.</p>
                        <!-- <a routerLink="/contact" class="book-btn">Get A Quote</a> -->
                    </div>

                    <div class="row border-top">
                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-sort"></i>
                                <div class="content">
                                    <h3>Fast Service</h3>
                                    <p>Quick and efficient service to meet your IT needs promptly and accurately.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-briefcase"></i>
                                <div class="content">
                                    <h3>Easy to Contact</h3>
                                    <p>Always available to address your queries and provide timely support and
                                        advice.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-analytics"></i>
                                <div class="content">
                                    <h3>IT Strategy</h3>
                                    <p>Strategic IT planning to guide your company towards innovation and success.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="choose-content-list">
                                <i class="flaticon-chess-pieces"></i>
                                <div class="content">
                                    <h3>Experienced Team</h3>
                                    <p>Highly skilled professionals with extensive experience to ensure top-notch IT
                                        solutions and consulting.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="choose-img-2">
                    <img src="assets/img/choose/1.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Choose Area End -->

<!-- Client Area -->
<div class="client-area-two ptb-100">
    <div class="container">
        <div class="section-title text-center">
            <span>Clients Review</span>
            <h2>Our Valued Clients Share Their Success Stories</h2>
            <p>At Cipher Solutions, we take pride in our clients' achievements. Our personalized and
                strategic IT solutions have empowered businesses to reach new heights. Hear directly from those
                who have experienced our commitment to excellence and integrity.</p>
        </div>

        <div class="client-slider owl-carousel owl-theme pt-45">
            <div class="client-card">
                <h3>Dilshad Patel</h3>
                <span>CEO Of Pink Hijabi</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>During our startup Cipher Solutions created a perfect brand guide, stunning logo, cohesive color
                    palettes, and seamless
                    online store setup.
                </p>
                <i class="flaticon-quote client-card-quote"></i>
                <!-- <div class="client-card-img">
                    <img src="assets/img/client/client-img1.png" alt="Images">
                </div> -->
            </div>

            <div class="client-card">
                <h3>Arsalan Ahmed</h3>
                <span>CEO Of My Guy Online Shopping</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>Cipher Solutions designed and built our fully cloud based cost effective serverless website, iOS, and
                    android app with SEO support.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <!-- <div class="client-card-img">
                    <img src="assets/img/client/client-img4.png" alt="Images">
                </div> -->
            </div>

            <div class="client-card">
                <h3>Marium</h3>
                <span>CEO Of My Professional Accountants</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>In less than a week our website was up and running. At Cipher Solutions they spend good time
                    understanding the clients requirements.</p>
                <i class="flaticon-quote client-card-quote"></i>
                <!-- <div class="client-card-img">
                    <img src="assets/img/client/client-img3.png" alt="Images">
                </div> -->
            </div>

            <div class="client-card">
                <h3>Johnson Dubula</h3>
                <span>Online Store Owner</span>
                <ul>
                    <li>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                        <i class='bx bxs-star'></i>
                    </li>
                </ul>
                <p>Cipher Solutions create all our Graphic Designs, they understand our requirements very well and
                    provide quality work.</p>
                <i class="flaticon-quote client-card-quote"></i>
            </div>
        </div>
    </div>
</div>
<!-- Client Area End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Expand Your Reach and Lead Your Industry</h2>
                <p>Unlock your business potential with expert IT services. Let us guide you to success.</p>
            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Request a Consultation</h2>
                            <p>Contact us today for a personalized consultation. Discover how we can elevate your
                                business.</p>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required
                                            placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required
                                            placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required
                                            class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                                            required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8"
                                            required data-error="Write your message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->