<!-- Inner Banner -->
<div class="inner-banner inner-bg9">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Our Services</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevron-right'></i></li>
                <li>Our Services</li>
            </ul>
        </div>
    </div>
</div>
<!-- Inner Banner End -->

<!-- Services Area -->
<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="service-widget-title">
            <div class="section-title text-center">
                <span>Our Services</span>
                <h2>Expert Consulting for Digital Transformation</h2>
                <p>Experience unparalleled expertise with our comprehensive suite of IT services. From innovative
                    software development and seamless web solutions to cutting-edge mobile apps and advanced data
                    science, we tailor our solutions to meet your unique business needs. Trust us to enhance your
                    digital presence and guide your business towards technological excellence and sustainable
                    growth.</p>
            </div>
        </div>

        <div class="row pt-45 pb-20">
            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="services-icon"><i class="flaticon-settings"></i></a>
                    <h3><a>Software Development</a></h3>
                    <p>Create custom software solutions tailored to your business needs for improved functionality and
                        growth.</p>
                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="services-icon"><i class="flaticon-menu"></i></a>
                    <h3><a>Web Development</a></h3>
                    <p>Build and optimize websites with modern technologies to enhance user experience and drive
                        engagement.</p>
                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="services-icon"><i class="flaticon-play-button"></i></a>
                    <h3><a>Mobile App Development</a></h3>
                    <p>Develop high-performance mobile apps for iOS and Android to enhance accessibility and user
                        engagement.</p>
                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="services-icon"><i class="flaticon-quote"></i></a>
                    <h3><a>UI/UX Design</a></h3>
                    <p>Create intuitive and engaging user interfaces that improve usability and drive user satisfaction.
                    </p>
                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="services-icon"><i class="flaticon-analytics"></i></a>
                    <h3><a>Data Science</a></h3>
                    <p>Leverage data analytics and machine learning to gain insights and drive strategic
                        decision-making.</p>
                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="services-icon"><i class="flaticon-double-right-arrows-angles"></i></a>
                    <h3><a>AI Solutions</a></h3>
                    <p>Implement AI-driven solutions like chatbots and automation to enhance operational efficiency.</p>
                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="services-icon"><i class="flaticon-magnifying-glass"></i></a>
                    <h3><a>Cloud Solutions</a></h3>
                    <p>Develop and manage cloud-based solutions to enhance scalability, security, and accessibility for
                        your business.</p>
                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="services-icon"><i class="flaticon-settings"></i></a>
                    <h3><a>Cybersecurity</a></h3>
                    <p>Protect your digital assets with advanced cybersecurity measures to safeguard against threats and
                        breaches.</p>
                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a class="services-icon"><i class="flaticon-sort"></i></a>
                    <h3><a>Systems Integration</a></h3>
                    <p>Integrate diverse IT systems and applications to streamline operations and improve overall
                        efficiency.</p>
                    <a class="services-more"><i class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>

            <!-- <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/services" class="page-numbers">2</a>
                    <a routerLink="/services" class="page-numbers">3</a>
                    <a routerLink="/services" class="next page-numbers">Next <i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div> -->
        </div>
    </div>
</div>
<!-- Services Area End -->

<!-- Leader Area -->
<div class="leader-area">
    <div class="container">
        <div class="leader-content">
            <div class="section-title text-center">
                <h2>Expand Your Reach and Lead Your Industry</h2>
                <p>Unlock your business potential with expert accounting services. Let us guide you to success.</p>

            </div>
        </div>
    </div>
</div>
<!-- Leader Area End -->

<!-- Contact Area -->
<div class="contact-area pb-100">
    <div class="container">
        <div class="contact-area-bg">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <div class="section-title text-center">
                            <h2>Request a Consultation</h2>
                            <p>Contact us today for a personalized consultation. Discover how we can elevate your
                                business.</p>
                        </div>

                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required
                                            placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required
                                            placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required
                                            class="form-control" placeholder="Phone">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                                            required placeholder="Your Subject">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8"
                                            required data-error="Write your message"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Area End -->